export const environment = {
  apiUrl: 'https://ablioconference.com/api',
  apiUrlUnsecure: 'http://ablioconference.com:4000/api',
  broadcasterUrl: 'https://local.ablioconference.com',
  enableProdMode: true,
  intercomAppId: 'o8xl0puz',
  kurentoHost: 'ws://ec2-34-249-68-3.eu-west-1.compute.amazonaws.com:8888/kurento',
  localKurentoHost: 'ws://local.ablioconference.com:8888/kurento',
  localMediaHost: 'http://local.ablioconference.com',
  mediaHost: 'http://ec2-34-249-68-3.eu-west-1.compute.amazonaws.com',
  name: 'production',
  serverUrl: 'https://ablioconference.com',
  stripePublicKey: 'pk_live_azNQKH5DysQFEln4DYzgCJYZ',
  wsHostname: 'ablioconference.com'
};
